import React from "react"
import { Checkbox, Grid, Modal } from "semantic-ui-react";
import { isMobile } from "@utils/Helpers";
import { ModalWrapper } from "./CancelThisPlate.styles";
import { KpFormDropdown, KpFormField } from "@styles/Global.forms";
import { KpButton } from "@elements/index";
import { ButtonTypes, LeadGenStatus, LeadGenType, LeadSource } from "@utils/Constant";
import theme from "@styles/theme";
import { LeadService } from "@services/LeadService";
import moment from "moment";
import { LOCALSTORAGE_RESERVATION } from "@redux/localStorage";

interface CancelThisPlateState {
    emailIsValid?: boolean
    currentEmail: string
    validatingEmail: boolean,
    isSubmitting: boolean
}

interface CancelThisPlateProps {
    apiUrl:string
    onClose: Function,
    modalOpen: boolean,
    combination: string,
    leadId: number,
    email: string,
    handleReservationNotification: any
}

class CancelThisPlate extends React.Component<CancelThisPlateProps,CancelThisPlateState>{

    constructor(props:CancelThisPlateProps){
        super(props);
        this.state = {
            currentEmail: "",
            emailIsValid: false,
            validatingEmail: false,
            isSubmitting: false
        }
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleSubmit = () => {
      this.setState({isSubmitting: true})
      const postData = {
            "LeadId": this.props.leadId,
            "combination": this.props.combination,
            "status": LeadGenStatus.UnReserved
        };

      this.setState({
        isSubmitting: true
      })
      LeadService.reserveNotifyCombination(
          this.props.apiUrl,
          postData
      ).then((result) => {
          this.setState({
            isSubmitting: false
          }, () => {
            localStorage.setItem(LOCALSTORAGE_RESERVATION,`|0`)
            this.props.handleReservationNotification(LeadGenStatus.UnReserved);
          })
      });
    }

    render() {
        return (
            <Modal className="lead-gen-modal" open={this.props.modalOpen} onClose={this.handleClose} centered={true} size={isMobile() ? "fullscreen" : "tiny"}>
                <ModalWrapper>
                <div className="close" onClick={this.handleClose}>
                  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.3828 2.88281C12.8711 2.39453 12.8711 1.60156 12.3828 1.11328C11.8945 0.625 11.1016 0.625 10.6133 1.11328L6.5 5.23047L2.38281 1.11719C1.89453 0.628906 1.10156 0.628906 0.613281 1.11719C0.125 1.60547 0.125 2.39844 0.613281 2.88672L4.73047 7L0.617188 11.1172C0.128906 11.6055 0.128906 12.3984 0.617188 12.8867C1.10547 13.375 1.89844 13.375 2.38672 12.8867L6.5 8.76953L10.6172 12.8828C11.1055 13.3711 11.8984 13.3711 12.3867 12.8828C12.875 12.3945 12.875 11.6016 12.3867 11.1133L8.26953 7L12.3828 2.88281Z" fill="#707070"/>
                  </svg>
                </div>
                    <img src={`https://api.kiwiplates.nz/api//image/214/${this.props.combination}/4/7/max/750`} width="100%" alt="rwetrt plate image" className="ui centered image css-4vx0xo e99udpt9"/>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                    <div className="title">Are You Sure?</div>
                    <div className="summary" style={{padding: "12px",paddingBottom: "12px"}}>Are you sure you want to cancel this plate reservation? This plate will become available once you cancel this reservation.</div>
                    <div style={{padding: "20px", paddingTop:"4px"}}>
                      <Grid width={16}>
                        <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="center"
                          >
                            <KpButton
                                id="reservePlateButton"
                                color={theme.brand.colors.green}
                                fullWidth="mobile tablet computer"
                                buttonType={ButtonTypes.Primary}
                                loading={this.state.isSubmitting}
                                type="submit"
                                onClick={this.handleSubmit}
                            >
                                CANCEL RESERVATION
                            </KpButton>
                            {isMobile() ?
                            <KpButton
                                style={{"marginTop": "8px"}}
                                id="reservePlateButton"
                                color={theme.brand.colors.green}
                                fullWidth="mobile tablet computer"
                                buttonType={ButtonTypes.Secondary}
                                loading={this.state.isSubmitting}
                                type="submit"
                                link={`/select-a-style/?combination=${this.props.combination}`}
                            >
                                BUY NOW
                            </KpButton> : <KpButton
                                style={{"marginLeft": "-15px", "marginTop": "8px"}}
                                id="reservePlateButton"
                                color={theme.brand.colors.green}
                                fullWidth="mobile tablet computer"
                                buttonType={ButtonTypes.Secondary}
                                loading={this.state.isSubmitting}
                                type="submit"
                                link={`/select-a-style/?combination=${this.props.combination}`}
                            >
                                BUY NOW
                            </KpButton>}
                          </Grid.Column>
                        </Grid.Row>
                        {/* <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="center"
                          >

                          </Grid.Column>
                        </Grid.Row> */}
                      </Grid>
                    </div>
                    <div className="tnc"><a href="/terms-and-conditions/?reserved=1" target="_blank">
                        Terms and Conditions Apply
                      </a></div>
                    </Grid.Column>
                </ModalWrapper>
            </Modal>)

    }
}

export default CancelThisPlate