import React, { Fragment } from "react"
import { Checkbox, Grid, Modal } from "semantic-ui-react";
import { isMobile } from "@utils/Helpers";
import { ModalWrapper } from "./ThankYou.styles";
import { KpFormDropdown, KpFormField } from "@styles/Global.forms";
import { KpButton } from "@elements/index";
import { ButtonTypes } from "@utils/Constant";
import theme from "@styles/theme";

interface ThankYouState {
    emailIsValid?: boolean
    currentEmail: string
    validatingEmail: boolean
}

interface ThankYouProps {
    apiUrl:string
    onClose: Function,
    modalOpen: boolean,
    combination: string
}
class ThankYou extends React.Component<ThankYouProps,ThankYouState>{

    constructor(props:ThankYouProps){
        super(props);
        this.state = {
            currentEmail: "",
            emailIsValid: false,
            validatingEmail: false,
        }
    }


    handleClose = () => {
        this.props.onClose();
    }

    render() {

        return (
            <Modal className="lead-gen-modal" open={this.props.modalOpen} onClose={this.handleClose} centered={true} size={isMobile() ? "fullscreen" : "tiny"}>

                <ModalWrapper>
                <div className="close" onClick={this.handleClose}>
                  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.3828 2.88281C12.8711 2.39453 12.8711 1.60156 12.3828 1.11328C11.8945 0.625 11.1016 0.625 10.6133 1.11328L6.5 5.23047L2.38281 1.11719C1.89453 0.628906 1.10156 0.628906 0.613281 1.11719C0.125 1.60547 0.125 2.39844 0.613281 2.88672L4.73047 7L0.617188 11.1172C0.128906 11.6055 0.128906 12.3984 0.617188 12.8867C1.10547 13.375 1.89844 13.375 2.38672 12.8867L6.5 8.76953L10.6172 12.8828C11.1055 13.3711 11.8984 13.3711 12.3867 12.8828C12.875 12.3945 12.875 11.6016 12.3867 11.1133L8.26953 7L12.3828 2.88281Z" fill="#707070"/>
                  </svg>
                </div>
                    <img style={{width:"53.33px"}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGsAAABQCAYAAAAeAotiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAATrSURBVHhe7Z2/UxNBFMfz//gPaGGJM7Qw2CL0oTfWBNs4asmMdlioM4xIYQpjIWpkoBAcaHSGHzNagCAFNme+l1tILu+S3Xt7d7uX95n5Nrnkctkvuffe7ltSCTTY/PUpaGzVg2rrXjCzfie4/fKGyIImV2+GY4qxxRiPYqhZOAFORr2RyL5m1ieCNz9eRaM/CGnW+eWfoPaxSp5QlL3q7Vpw9PcgcuOaAbOOO0+623GYOokoP+FbFjeszywxyi3FDesza745Rb5IVJwWOjmD4sqslf3n5JNFxWtl/1noUWiW3P7cFlL888uzrllrnXSRepLIHeHbFZpV/SC1lOtC7Kqc/zsjD1KaXL0VFm14jcADY4g7Gm5x1FhTqmz+/kweoLR3+j16K8EWJuOvbVb1/Wx0esE2c81pcszjqugmF4tfa9GpBdsstmvkmMclZjmAmOURYpZHiFkeYd0sSM1RCfYwmZM1Mgta3nkSvY3A5dH2Q3KMk2RsFoRVZGolU9ADK/Fp2iVSmQVRK5nCaDgrHKnNgjCv1Tp8F12GMAo0IJnMBcbFMktJ4thobCzuWjELQrAUBkF8Mk0kkmTNLGi+OS1xrAfEJ5t9LVbNgiTx6LJ3smu9VcK6WRCC6DgX0Gs/X7MSiSRlYpbSOCYey7tPybGwoUzNglBAozOn7OTRcp65WVDZ4xi3lQ+9LdTjcWmbhVl33NaoYzqCYTrbWnyDW+g2tpayWyJ5wSzuyhTHuIUuxhJkup61d4q0NP2mujIU0JxCFxsSezvFMl98PL44ZBnmawGddsZcKfzcnbHrJZeVYjQqNlh/YX4lHtxEov6lRjbI5mKWgpN4IDgP25rpCtxCd/nb4+hMg+RqFmgdNnkfxuHEg1PoIi3HGA8jd7MAN45hL61LBTTiU739gLxWHSGRiMcnikLMAjAMQZQ6h45ciWPcGfNqa1Z7A0dhZim4BTRmrYuCO2OOQteEws0CHMOgImbubRW6JjhhFuAW0HkmHpxCF4kEduSkwRmzADfxwK6/LOMYt9DVTSSScMosgGBb20i/hJBV4sEtdGsbC9qJRBLOmaXgFtA2W9+4M+bDCl0TnDULIAgXXUBzEgmdQtcEp80C3DiGZCBNAc1tDYvPmNvAebOAjQLaZF4Rtz1OfKJmzG3ghVkKzsw9BNNwW9s72YnOeA0ewzHu/000LXRN8MoswC2ge4VbFUQdS6M0ha4J3pkFMHPPiWO2xSl0TfDSLMBNPGyJW+ia4K1ZgFtAc2UyY24Dr81S2IxjusoykUiiFGYBbgGtK8SnrBOJJEpjFsg6juG2l1d8oiiVWQpcq03TkES40KxTSrMUuGZ8G6jr1BFe69Je6FKbpcDtEdePDzvXnArjTvy68RiOLbbvh8/NM8vTZSzMSuLo4iCUL4y1Wb4hZnmEmOURYpZHiFkeIWZ5hLZZWEOiDsSFf2ctZIPuL1ZU0NdNHaBUxg3cRWMy/oY/b9Hd+IYOIYEHxhAhyGRFIfzhmAX54RjnhV+sCM3i7pwQZS/c0UKzcCvMY4FPlE6qHyQ0C2CVlHqiqHipFewrs4DELveELmBFn1mutIGJuoq3w/WZBcQwN0T1LQ6YBWCY7hSIyL6SNuiRZilQtMm3LD+hN2RzSLv2ULMUOEFjeyk8GdXnIEqnmbcT4ZhibIeZ1CUI/gM9gK+M9XDsUAAAAABJRU5ErkJggg==" width="100%" alt="rwetrt plate image" className="ui centered image css-4vx0xo e99udpt9"/>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                    <div className="title">Thank You!</div>
                    <div className="summary">We will notify you when this plate becomes available and is no longer reserved. </div>
                    <div className="plate">Plate combination: <span>{this.props.combination.toUpperCase()}</span></div>
                    <div style={{padding: "20px"}}>
                      <Grid width={16}>
                        <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="center"
                          >
                            <KpButton
                                id="signin-submit"
                                color={theme.brand.colors.green}
                                fullWidth="mobile tablet computer"
                                buttonType={ButtonTypes.Primary}
                                loading={false}
                                type="submit"
                                onClick={this.handleClose}
                            >
                                CLOSE
                            </KpButton>
                          </Grid.Column>

                        </Grid.Row>
                      </Grid>
                    </div>
                    </Grid.Column>
                </ModalWrapper>
            </Modal>)

    }
}

export default ThankYou