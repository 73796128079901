import React, { Fragment } from "react"
import { Checkbox, Grid, Input, Modal } from "semantic-ui-react";
import { isMobile, UpperCaseArray } from "@utils/Helpers";
import { ErrorWrapper, ModalWrapper } from "./ReservedThisPlate.styles";
import { KpFormDropdown, KpFormField, KpFormFieldAsDT, KpFormInputErrorMessage } from "@styles/Global.forms";
import { KpButton } from "@elements/index";
import { ButtonTypes, LeadGenStatus, LeadGenType, LeadSource } from "@utils/Constant";
import theme from "@styles/theme";
import { EmailValidationService } from "@services/EmailValidationService";
import { LeadService } from "@services/LeadService";
import { LOCALSTORAGE_RESERVATION } from "@redux/localStorage";
import moment from "moment";
import Cookies from "js-cookie";
import { object } from "@storybook/addon-knobs";

interface ReservedThisPlateState {
    emailIsValid?: boolean
    currentEmail: string
    validatingEmail: boolean
    formData: any,
    isSubmitting: boolean,
    validated: boolean,
    noPlateReserve: boolean
}

interface ReservedThisPlateProps {
    apiUrl:string
    onClose: Function,
    modalOpen: boolean,
    combination: string,
    carMake: any,
    handleReservationNotification: any,
    emailValidationToken: string
}

class ReservedThisPlate extends React.Component<ReservedThisPlateProps,ReservedThisPlateState>{

    constructor(props:ReservedThisPlateProps){
        super(props);
        this.state = {
            currentEmail: "",
            emailIsValid: false,
            validatingEmail: false,
            formData: {
              OptOut: false,
              AgreeTermAndCondition: false,
              carMake:'',
            },
            isSubmitting: false,
            validated: false,
            noPlateReserve: false
        }
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleValidation= () => {
      var message = '';
      var formData = this.state.formData
      
      let length = 12
      if(Object.keys(formData).find(e => e== 'carMake-error')){
        length = 13
      }

      if(Object.keys(formData).length === length-(this.props.carMake.length > 0 ? 0 : 1)){
        for (const key in formData) {
          if(key.includes('error')){

            if(formData[key] != ''){
              message = 'Error Value'
            }
          }
          if(key == 'AgreeTermAndCondition'){
            if(formData[key] == false){
              message = 'Error Value'
            }
          }
        }
      } else {
        message = 'Not All Filled up!'
      }

      this.setState({validated: message === '' && !this.state.noPlateReserve})
    }

    handleFormData = (field: string, value: string, type: string = 'letter') => {
      var formData = this.state.formData

      formData[field] = value;

      if(field != 'carMake'){
        formData[`${field}-error`] = '';
        if(value.trim() === ""){
          formData[`${field}-error`] = `${UpperCaseArray(field)} is required!`;
        }
        this.setState({formData: formData}, () => {
          this.handleValidation()
        })
      } else {
        formData[`${field}-error`] = '';
        this.setState({formData: formData}, () => {
          this.handleValidation()
        })
      }     
    }

    handleCBFormDataValidation = (field: string, value: boolean) => {
      var formData = this.state.formData

      formData[field] = !value;

      formData[`${field}-error`] = '';
      if(!formData[field]){
        formData[`${field}-error`] = `Agree on term & condition is required!`;
      }
      this.setState({formData: formData}, () => {
        this.handleValidation()
      })
    }

    handleCBFormData = (field: string, value: boolean) => {
      var formData = this.state.formData
      formData[field] = !value;
      this.setState({formData: formData})
    }

    handleSubmit = () => {
      var formData = this.state.formData
      const postData = {
            "FirstName": formData.firstName,
            "LastName": formData.lastName,
            "Email": formData.email,
            "Phone": formData.mobile,
            "VehicleMake": formData.carMake,
            "VehicleType": "Car",
            "Combination": this.props.combination,
            "ReservationType": 8,
            "ToBeNotified": false,
            "OptOut": formData.OptOut,
            "LeadSource": LeadSource.Reservation,
            "Type": LeadGenType.Reservation,
            "Status": LeadGenStatus.Reserved
        };


      this.setState({
        isSubmitting: true
      })

      Cookies.set('click-reservation', "true");
      LeadService.reserveNotifyCombination(
          this.props.apiUrl,
          postData
      ).then((result) => {
          this.setState({
            isSubmitting: false,
            formData: {},
          }, () => {
            var expireOn = moment().add(3, 'days').format('YYYYMMDDHHmmss')
            localStorage.setItem(LOCALSTORAGE_RESERVATION,`${this.props.combination}|${expireOn}`)
            this.props.handleReservationNotification(LeadGenStatus.Reserved);
          })
      });
    }

    validateEmailAddress = (field: string, value: any) => {
      var formData = this.state.formData
      formData[field] = value

        this.setState({
          currentEmail: value,
          validatingEmail: true,
          emailIsValid: undefined,
        })
        let error
        return EmailValidationService.validate(
          this.props.emailValidationToken,
          value,
          true
        ).then((result: any) => {
          formData[`${field}-error`] = '';
          if(value!= undefined && value.trim() === ""){
            formData[`${field}-error`] = `${UpperCaseArray(field)} is required!`;
          }
          if (result.success == true) {
            formData[`${field}-error`] = ``;
            this.setState({
              validatingEmail: false,
              emailIsValid: true,
              formData: formData
            }, () => {this.handleValidation()})

          } else {
            formData[`${field}-error`] = `Invalid Email`;
            this.setState({
              validatingEmail: false,
              emailIsValid: false,
              formData: formData
            }, () => {this.handleValidation()})
          }
        })

    }

    handleBlur = () => {

      LeadService.getCombinationByEmail(
        this.props.apiUrl,
        this.state.formData['email']
      ).then((result) => {
          this.setState({
            noPlateReserve: !result.Success
          })
          this.validateEmailAddress('email', this.state.formData['email'])
      });
    };

    render() {
        return (
            <Modal className="lead-gen-modal" open={this.props.modalOpen} onClose={this.handleClose}  centered={true} size={isMobile() ? "fullscreen" : "tiny"}>
                <ModalWrapper>
                <div className="close" onClick={this.handleClose}>
                  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.3828 2.88281C12.8711 2.39453 12.8711 1.60156 12.3828 1.11328C11.8945 0.625 11.1016 0.625 10.6133 1.11328L6.5 5.23047L2.38281 1.11719C1.89453 0.628906 1.10156 0.628906 0.613281 1.11719C0.125 1.60547 0.125 2.39844 0.613281 2.88672L4.73047 7L0.617188 11.1172C0.128906 11.6055 0.128906 12.3984 0.617188 12.8867C1.10547 13.375 1.89844 13.375 2.38672 12.8867L6.5 8.76953L10.6172 12.8828C11.1055 13.3711 11.8984 13.3711 12.3867 12.8828C12.875 12.3945 12.875 11.6016 12.3867 11.1133L8.26953 7L12.3828 2.88281Z" fill="#707070"/>
                  </svg>
                </div>
                    <img src={`https://api.kiwiplates.nz/api//image/214/${this.props.combination}/4/7/max/750`} style={{width:"209px"}} alt="rwetrt plate image" className="ui centered image css-4vx0xo e99udpt9"/>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                    <div className="title">Reserve this Plate</div>
                    <div className="summary">We will hold this plate combination for you for 3 days. Within this time only you can purchase this plate using the link provided.</div>
                    <div style={{padding: "20px"}}>
                      <Grid width={16}>
                        <Grid.Row>
                          <Grid.Column
                            className="coll"
                            mobile={8}
                            tablet={8}
                            computer={8}
                            textAlign="center"
                          >
                            <KpFormFieldAsDT>
                              <KpFormInputErrorMessage>
                                  {this.state.formData['firstName-error']}
                              </KpFormInputErrorMessage>
                              <input
                                id="firstName"
                                name="firstName"
                                type="text"
                                data-private="lipsum"
                                aria-label="First Name"
                                placeholder="First Name"
                                value={this.state.formData['firstName']}
                                onChange={e => this.handleFormData('firstName', e.target.value)}
                              ></input>
                            </KpFormFieldAsDT>
                          </Grid.Column>
                          <Grid.Column
                            className="colr"
                            mobile={8}
                            tablet={8}
                            computer={8}
                            textAlign="center"
                          >
                            <KpFormFieldAsDT>
                              <KpFormInputErrorMessage>
                                  {this.state.formData['lastName-error']}
                              </KpFormInputErrorMessage>
                              <input
                                id="lastName"
                                name="lastName"
                                aria-label="Last Name"
                                data-private="lipsum"
                                type="text"
                                placeholder="Last Name"
                                value={this.state.formData['lastName']}
                                onChange={e => this.handleFormData('lastName', e.target.value)}
                              ></input>
                            </KpFormFieldAsDT>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="center"
                          >

                            <KpFormFieldAsDT>
                              <KpFormInputErrorMessage>
                                  {this.state.formData['email-error']}
                              </KpFormInputErrorMessage>
                              <input
                                style={{borderColor:"red"}}
                                id="email"
                                name="email"
                                type="text"
                                data-private="lipsum"
                                aria-label="Email"
                                placeholder="Email"
                                value={this.state.formData['email']}
                                onChange={e => this.validateEmailAddress('email', e.target.value)}
                                onBlur={this.handleBlur}
                              ></input>
                            </KpFormFieldAsDT>
                            {this.state.noPlateReserve && <ErrorWrapper>This email address already has a plate reserved</ErrorWrapper>}
                          </Grid.Column>

                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="center"
                          >
                            <KpFormFieldAsDT>
                              <KpFormInputErrorMessage>
                                  {this.state.formData['mobile-error']}
                              </KpFormInputErrorMessage>
                              <input
                                id="mobile"
                                name="mobile"
                                type="number"
                                data-private="lipsum"
                                aria-label="Mobile"
                                placeholder="Mobile"
                                value={this.state.formData['mobile']}
                                onChange={e => this.handleFormData('mobile', e.target.value, 'number')}
                                min="0"
                              ></input>
                            </KpFormFieldAsDT>
                          </Grid.Column>

                        </Grid.Row>
                        {this.props.carMake.length > 0 && <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="center"
                          >
                            <KpFormFieldAsDT>
                              <KpFormDropdown
                                id="carMake"
                                fluid
                                selection
                                placeholder={(<span style={{color: "#707070"}}>Car Make</span>)}
                                backgroundColor={theme.brand.colors.white}
                                icon="chevron down"
                                options={this.props.carMake}
                                value={this.state.formData['carMake']}
                                onChange={(e:any) => this.handleFormData('carMake', e.currentTarget.innerText.includes("\n") ? '' : e.currentTarget.innerText)}
                              />
                            </KpFormFieldAsDT>
                          </Grid.Column>

                        </Grid.Row>}
                        <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="left"
                            style={{marginBottom:"12px"}}
                          >
                            <Checkbox
                                style={{display:"inline"}}
                                id="optIn"
                                className="bcopt"
                                onClick={(e:any) => this.handleCBFormData('OptOut', this.state.formData['OptOut'])}
                                checked={!this.state.formData['OptOut']}/> <div className="span">Keep me updated on this plate combination, new plate designs and promotions</div>
                          </Grid.Column>
                          {this.state.formData['AgreeTermAndCondition-error'] && <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="left"
                            style={{marginBottom:"12px"}}
                          >
                              <KpFormInputErrorMessage>
                                  {this.state.formData['AgreeTermAndCondition-error']}
                              </KpFormInputErrorMessage>                            
                          </Grid.Column>}
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="left"
                            style={{marginBottom:"12px"}}
                          >
                            <Checkbox
                                style={{display:"inline"}}
                                id="agreetc"
                                className="bcopt"
                                onClick={(e:any) => this.handleCBFormDataValidation('AgreeTermAndCondition', this.state.formData['AgreeTermAndCondition'])}
                                checked={this.state.formData['AgreeTermAndCondition']}/> <div className="span">I agree to the </div><a href="/terms-and-conditions/?reserved=1" className="tc" target="_blank">Terms & Conditions</a><div  className="span-right"></div>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            textAlign="left"
                          >
                            <KpButton
                                id="reservePlateButton"
                                color={theme.brand.colors.green}
                                fullWidth="mobile tablet computer"
                                buttonType={ButtonTypes.Primary}
                                loading={this.state.isSubmitting}
                                type="submit"
                                disabled={!this.state.validated}
                                onClick={this.handleSubmit}
                            >
                                DONE
                            </KpButton>
                          </Grid.Column>

                        </Grid.Row>
                      </Grid>
                    </div>
                    </Grid.Column>
                </ModalWrapper>
            </Modal>)

    }
}

export default ReservedThisPlate